<template>
  <v-app>
    <HeaderView />
    <v-main>
      <router-view />
    </v-main>
    <FooterView />
  </v-app>
</template>

<script>
import HeaderView from "@/components/Layout/Header.vue";
import FooterView from "@/components/Layout/Footer.vue";

export default {
  name: "GenerationWeb",
  components: { FooterView, HeaderView },

  data: () => ({
    //
  }),
};
</script>
