<template>
  <footer aria-label="Footer">
    <div class="footer-content">
      <p>&copy; 2024 GenerationWeb Ltd. All rights reserved.</p>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterView",
};
</script>
