<template>
  <header class="header">
    <nav aria-label="Main Navigation">
      <div class="d-flex justify-space-between">
        <div class="navigation justify-start">
          <router-link class="nav-link" to="/" aria-label="Home"
            >HOME</router-link
          >
          <router-link class="nav-link" to="/about" aria-label="About"
            >ABOUT</router-link
          >
          <router-link class="nav-link" to="/projects" aria-label="About"
            >PROJECTS</router-link
          >
          <router-link class="nav-link" to="/contact" aria-label="Contact"
            >CONTACT</router-link
          >
        </div>
        <div class="logo d-flex align-center"></div>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "HeaderView",
};
</script>
