<template>
  <div class="home-content">
    <p class="headline">
      At <span style="color: #ffffff">GenerationWEB</span> it's more than just
      development...
    </p>
    <p class="strapline">
      Seasoned and professional with over 18 years of experience in delivering
      top-notch web solutions.
    </p>
    <p>
      A journey that has seen work across diverse environments, from high-end
      agencies to pivotal contract roles, developing a unique blend of skills
      and passion for web development.
    </p>
    <p class="foot">
      Specializing in full-stack development with a strong focus on PHP and
      JavaScript.
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
});
</script>
